<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar-new v-model="appliedFilters" @apply-filter="applyFilter" :defaultStatus="null"></filter-side-bar-new>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: appliedFilters.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">{{ $t('Todos') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: appliedFilters.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.task.status.active')
                    }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: appliedFilters.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.task.status.inactive')
                    }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: appliedFilters.id_status === 3 }"
                     v-on:click="applyStatusFilter(3)" data-toggle="tab">{{ $t('form.task.status.expired')
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="appliedFilters" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!--  Actions -->
                <div class="flexbox px-4 py-3 mb-3">
                  <div class="d-flex align-items-center">

                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <router-link :to="{name: 'SchedulesBulkNew'}" class="btn btn-outline-light  pull-right">
                      Novo
                    </router-link>
                  </div>
                </div>
                <!--  End Actions -->
                <!-- Table -->
                <custom-vue-table
                  ref="vuetable"
                  http-method="get"
                  :api-url="processEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="appliedFilters"
                  track-by="id"
                >
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a class="btn btn-link btn-padding" :title="$t('actions.log')"
                         v-bind:href="mountLink('SchedulesBulkLog', props.rowData)">
                        <i class="text-primary  ft-file-text font-20"></i>
                      </a>
                      <a class="btn btn-link btn-padding" :title="$t('actions.edit')"
                         v-bind:href="mountLink('SchedulesBulkEdit', props.rowData)">
                        <i class="text-primary  ti-pencil font-20"></i>
                      </a>
                      <a class="btn btn-link btn-padding" :title="$t('actions.delete')"
                         v-on:click="onDelete(props.rowData)">
                        <i class="text-primary  ti-trash font-20"></i>
                      </a>
                    </div>
                  </template>
                </custom-vue-table>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import FilterSideBarNew from '@/components/FilterSideBarNew'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Components
import CustomVueTable from '@/components/Table/CustomVueTable'
// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

import VModal from 'vue-js-modal'
// Services
import TaskService from '@/services/TaskService'

Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

export default {
  name: 'TaskTariffBulkList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tariffs_bulk') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task/tariff/bulk',
      appliedFilters: {
        disparador_nome: null,
        id_status: null
      },
      sortOrder: [
        { field: 'u_status', direction: 'asc' }
      ]
    }
  },
  components: {
    CustomVueTable,
    FilterSideBarNew,
    Loading,
    FilterContainer
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
  },
  methods: {
    onDelete (task) {
      this.$alertSwal.modalConfirm('', 'Você tem certeza de que deseja excluir o agendamento emmassa.<br/>Esta ação irá <strong>excluir todos os tarifários gerados automaticamente</strong>.<br />Está certo desta ação?', 'warning', 'Sim', 'Não', (e) => this.delete(task)).then((result) => {
        if (result.value) {
          this.$alertSwal.toast('Tarefa excluída com sucesso!')
        }
      })
    },
    async delete (task) {
      let _this = this
      _this.isLoading = true

      await TaskService.deleteTaskTariffBulk(task.id).then(task => {
        _this.isLoading = false
        _this.$refs.vuetable.refresh()
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    mountLinkCopy (itemName, object) {
      return this.$router.resolve({ name: itemName, query: { id_schedule: object.id } }).href
    },
    // Loader
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.appliedFilters.id_status = idStatus
      this.applyFilter()
    },
    applyFilter () {
      if (this.$refs.vuetable !== undefined) {
        this.$refs.vuetable.onFilter()
      }
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.task.status.active'),
          'color': '#5ec65f'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.task.status.inactive'),
          'color': '#ababab'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.task.status.expired'),
          'color': '#f1ba26'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'titulo',
          title: this.$i18n.t('taskCampaign.title'),
          sortField: 'u_title',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'descricao',
          title: this.$i18n.t('taskCampaign.description'),
          sortField: 'u_description',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'criado_por',
          title: this.$i18n.t('form.task.created_by'),
          sortField: 'createdBy_email',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatUser(value)
        },
        {
          name: 'status',
          title: this.$i18n.t('form.task.status.title'),
          sortField: 'u_status',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          width: '250px',
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    process () {
      return this.$route.params.process || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  }
}
</script>

<style>
.swal2-popup {
  width: inherit !important;
}
.disableLabel span{
  background-color: rgba(132,117,78,0.6) !important;
  border-color: rgba(132,117,78,0.1) !important;
}
.wrap-actions-large{
  width: 300px;
}
</style>
